import React, {useState} from "react"
import { navigate } from "gatsby"
import { handleLogin, isLoggedIn } from "../components/Services/auth"
import "../components/Contact/contactus.scss";

export default function Login(props) {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')

  const handleUpdate = (event) => {
    const name = event.target.name;
    if (name === "username") {
      setUsername(event.target.value);
    } else if (name === "password") {
      setPassword(event.target.value);
    } else {
      console.error("Invalid event");
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    return handleLogin({
      "username": username,
      "password": password,
    })
  }

  if (isLoggedIn()) {
    navigate("/")
  }
    
  return (
    <>
      <div className="contactus-form">
        <h2>Vendor Login Form</h2>
        <form
          className="row"
          method="post"
          onSubmit={event => {
            if (handleSubmit(event)) {
              navigate(props.location.state.next);
              return null;
            }
            setError('Invalid credentials')
          }}
        >
          <div className="form-group col-md-12">
            <label className="form-label">
              *Username
            </label>
            <input
                type="text"
                name="username"
                onChange={handleUpdate}
                required
                className="form-control form-control-lg"
              />
          </div>
          <div className="form-group col-md-12">
            <label className="form-label">
              *Password
            </label>
            <input
                type="password"
                name="password"
                onChange={handleUpdate}
                required
                className="form-control form-control-lg"
              />
          </div>
          {error &&
            <div className="col-md-12">
              <div className="form-group text-danger">
                {error}
              </div>
            </div>
          }
          <div className="col-md-12">
            <div className="form-group btn-wrapper">
              <button type="submit" className="btn">Log In</button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}